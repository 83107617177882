import React from "react"
import Header from "../../components/header"
import Footer from "../../components/footer"
import HeaderLeistung from "../../components/headerLeistung"
import LeistungBox from "../../components/boxLeistung"
import ContactBox from "../../components/boxContact"

import imgBalkon01 from "../../assets/leistungen/balkon01.jpg"
import imgBalkon02 from "../../assets/leistungen/balkon02.jpg"
import imgBalkon03 from "../../assets/leistungen/balkon03.jpg"

export default () => (
  <div>
    <Header />
    <HeaderLeistung
      Title="Terassen &amp; Balkone"
      Content="Balkone und Terrassen sind nichts anderes als abgedichtete Flachdächer, die höheren mechanischen Belastungen – etwa durch das Begehen – ausgesetzt sind. Diese Belastungen erhöhen die Gefahr der Beschädigungen an der Abdichtung. Wichtig sind daher eine gute Planung sowie die professionelle Ausführung der Abdichtung. Wir haben Erfahrung im Aufbau von Balkonen und Terrassen und beraten Sie ausführlich zu den unterschiedlichen Belägen."
    />
    <LeistungBox
      Color="gray"
      Image={imgBalkon01}
      IsImageLeft={true}
      Title="Terassen &amp; Balkone"
      Content="Mal entspannen und frische Luft auf dem Balkon oder der Terrasse genießen – wer träumt an warmen Sonnentagen nicht davon. Balkone und Terrassen sind besondere Details eines Gebäudes und bieten ein höheres Maß an Wohnkomfort. Allerdings sollte deren Beschaffenheit genauso „okay“ sein wie das Wetter. Wenn etwa der Untergrund schadhaft ist, ist der eigene Verdruss bzw. der Ärger mit den Nachbarn vorprogrammiert."
    />
    <LeistungBox
      Color="white"
      Image={imgBalkon02}
      IsImageLeft={false}
      Title="Beschichtungen mit Flüssigkunststoff"
      Content="Der Flüssigkunststoff lässt sich je nach Untergrund direkt auf dem vorhandenen Belag aufbringen. Dadurch ist eine nahtlose Anpassung an die unterschiedlichen Konstruktionsformen möglich. Außerdem kann eine sichere Detail- und Fugenabdichtung gewährleistet werden. Wer sich gestalterisch verwirklichen möchte, dem stehen eine große Farbauswahl und Oberflächenvarianten zur Verfügung."
    />
    <LeistungBox
      Color="gray"
      Image={imgBalkon03}
      IsImageLeft={true}
      Title="Terrassenbeläge"
      Content="Erst der Belag gibt Balkonen und Terrassen den gewünschten Charakter: Vom geradlinig-modernen Stil mit Beton-, Stein- oder Keramikplatten bis hin zum warm-natürlichen Charme durch Holz- oder WPC-Dielen. Egal für welchen Belag Sie sich entscheiden, wichtig ist die Verlegung. Sie ist mit einer gewissen Distanz zur Abdichtung vorzunehmen, um einen problemlosen Abfluss des Ihre Ansprüche geeigneten Belag zu finden. Denn bei der Pflegebedürftigkeit der Materialien gibt es große Unterschiede.
        <br />
        Naturhölzer, etwa Lärche, Douglasie oder auch tropische Harthölzer wie Bangkirai, erfordern eine höhere Aufmerksamkeit als beispielsweise Steinbeläge oder WPC-Dielen (Holz-Kunststoff-Verbundmaterial)"
    />
    <ContactBox isFoot="true" />
    <Footer />
  </div>
)
